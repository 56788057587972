<script setup lang="ts">
import type { Strapi4ResponseSingle } from '@nuxtjs/strapi/dist/runtime/types/v4'
import { definePageMeta } from '#imports'

const { findOne } = useStrapi()
const { locale } = useI18n()
const config = useRuntimeConfig()

definePageMeta({ auth: false })

const cms = ref()

const { data } = await useCachedAsyncData<Strapi4ResponseSingle>(
  `homepage_${locale.value}`,
  () =>
    findOne('homepage', {
      populate:
        'blocks, blocks.tabs, blocks.partners, blocks.partners.logo, blocks.partners.background, blocks.background_mobile, blocks.file, blocks.logo, blocks.items, blocks.interlocutors.items, blocks.interlocutors.image, blocks.key-figures.items, blocks.items.button, blocks.button_1, blocks.button_2, blocks.funds, blocks.image, blocks.image_mobile, blocks.offers, blocks.offers.image, blocks.offers.link, blocks.background, blocks.items.logo, blocks.items.link.page, blocks.items.image, blocks.button.page, seo, seo.metaImage, seo.metaSocial, seo.metaSocial.image, seo.structuredData',
      locale: locale.value,
    }),
  {
    serverMaxAge: config.public.serverMaxAge,
  }
)

if (data.value && data.value.data) cms.value = data.value.data.attributes

if (data.value && data.value.data && data.value.data.attributes.seo) {
  const seo = data.value.data.attributes.seo
  const { meta, link } = useSeo(seo)
  useHead({
    titleTemplate: (titleChunk) => {
      if (seo.metaTitle) return seo.metaTitle
      return titleChunk
    },
    meta,
    link,
  })
}

onMounted(() => {
  if (
    data.value &&
    data.value.data &&
    data.value.data.attributes &&
    data.value.data.attributes.seo &&
    data.value.data.attributes.seo.structuredData
  ) {
    const script = document.createElement('script')
    script.setAttribute('type', 'application/ld+json')
    script.innerHTML = JSON.stringify(
      data.value.data.attributes.seo.structuredData
    )
    document.head.appendChild(script)
  }
})
</script>

<template>
  <div v-if="cms" class="home">
    <TemplatesEditorial
      v-if="data && data.data"
      :content="data.data.attributes.blocks"
    />
  </div>
</template>

<style lang="scss" scoped>
.home {
  :deep(.blocks-area > section) {
    @apply mb-100;
  }
}
</style>
